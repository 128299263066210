
.slick-dots{
  position: static;
    width: fit-content;
    margin: auto;
    color: #d16f36
}
  .slick-dots li button {
    border-radius: 50%;
    padding: auto;
    cursor: pointer;
    color: gray;
  }
  .slick-dots li button:before{
    color: gray;
  }
  .slick-dots li.slick-active button:before{
    color: #d16f36;
    
  }
  .slick-dots li.slick-active button {
    border: solid 1px #d16f36;
    color: #d16f36;
    
  }
  .slick-arrow{
    display: none !important;
  }


  @media screen and (min-width: 2000px){
    .bg_gradient_sharp{
      background: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 45.2%, #1d1b1a 30%, #1d1b1a 100%);
    }  
  }

  