.imageComponent {
  position: relative;
  height: 88%;
  max-height: 100%;
}

.imageComponent > img {
    border-radius: 1px;
    height: 100%;
}

.gallerySliderComponent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.gallerySliderScrollComponent {
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  scroll-behavior: smooth;
  margin-top: 5px;
}

.gallerySliderScrollComponent::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
  background-image: linear-gradient(to right, #0000, #ab6034);;
  border-radius: 10px;
}

.gallerySliderScrollComponent::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to right, #0000, #ab6034);;
  border-radius: 10px;
}

.selectedGallerySliderCardComponent {
  width: 100px;
  height: 60px;
  padding: 2px;
  border-radius: 1px;
  flex-grow: 1;
  overflow: hidden;
  display: inline-block;
  background-image: linear-gradient(to right, #0000, #ab6034);;
  cursor: pointer;
}

.gallerySliderCardComponent {
  width: 100px;
  height: 60px;
  padding: 2px;
  cursor: pointer;
  flex-grow: 1;
  overflow: hidden;
  display: inline-block;
}

.gallerySliderCardComponent > img {
  border-radius: 1px;
  height: fit-content;
}

.selectedGallerySliderCardComponent > img {
  border-radius: 1px;
}

.PrevIcon {
  position: absolute;
  /* left: 10%; */
  top: 50%;
  left: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #000, #ab6034);;
  border-radius: 100px;
  padding: 5px;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.nextIcon {
  position: absolute;
  top: 50%;
  right: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #000, #ab6034);;
  border-radius: 100px;
  padding: 5px;
  z-index: 1;
  cursor: pointer;
}

:is(.nextIcon:active, .PrevIcon:active) {
  scale: 0.8;
}
