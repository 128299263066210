@import url("https://fonts.googleapis.com/css2?family=Satisfy&family=Source+Serif+4:opsz@8..60&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: "Satisfy", cursive;
  font-family: "Source Serif 4", serif;
}
