.rootModalOverLayComponent {
  background-color: #00000055;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
 
}

.modalContainer {
  width: 50%;
  height: 50%;
  background-color: white;
  position: absolute;
  border-radius: 12px;
  z-index: 2;
  padding: 5px;
  animation: var(--global-zoom-in-out) 1s ease;
}
