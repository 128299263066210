.no-scrollbar{
  overflow-y: hidden;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hide scrollbar thumb */
}

  .animate-move-down {
    transition: transform 1s ease-out;
  }

  .animate-move-down:hover {
    transform-origin: top;
  transform: translateY(100%) rotateX(90deg);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
