
.slick-dots{
    width: fit-content;
    margin: auto;
}
  .slick-dots li button {
    border-radius: 50%;
    padding: auto;
    cursor: pointer;
  }
  
  .slick-dots li.slick-active button {
    /* border: solid 2px whitesmoke;
    background-color: white; */
    /* padding: 0.6rem; */
  }
  .slick-arrow{
    display: none !important;
  }


  @media screen and (min-width: 2000px){
    .bg_gradient_sharp{
      background: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 45.2%, #1d1b1a 30%, #1d1b1a 100%);
    }  
  }

  