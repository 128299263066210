.card {
  position: relative;
  width: 100%;
  height: 300px;
}

.card .face {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  box-sizing: border-box;
}
.content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card .face .face1 {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100%;
  transform-origin: bottom;
  background-repeat: no-repeat;
  background-position: center;
   background-size: cover;
  background-size: 100% 100%;
}

.card .face .face1 img {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: contain;
  mix-blend-mode: color-burn;
  opacity: 100%;
}

.card .face .face1 h3 {
  position: absolute;
  display: flex;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
  font-family: "Times New Roman", Times, serif;
  padding: 12px 15px;
  background-color: var(--color-dark-green);
  justify-content: center;
  align-items: center;
  top: -4px;
  left: 0;
  font-weight: 500;
  overflow: hidden;
  min-width: max-content;
  border-radius: 0 0 20px 0;
}

.title h1 {
  font-size: 1.5em;
}

.card:hover .face.face1 {
  transform: translateY(-100%) rotateX(90deg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-size: 100% 100%;
  opacity: 50%;
}

.card .face .face2 {
  background: #ab6034;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transform-origin: top;
  transform: translateY(100%) rotateX(90deg);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.card:hover .face .face2 {
  transform: translateY(0) rotateX(0deg);
}

.service_mobile_card {
  position: relative;
  max-height: 300px;
  background-color: var(--color-dark-green);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  overflow: hidden;
  border-radius: 20px;
  padding: 10px;
}

.service_mobile_card > div:nth-child(1) {
  width: 100px;
  height: 100px;
  padding: 5px;
  border-radius: 100px;
  /* float: left; */
  overflow: hidden;
  margin: 4% 4% 0 4%;
  background-color: var(--color-light-green);
}

.service_mobile_card > div:nth-child(1) h3 {
    position: absolute;
    display: flex;
    color: #fff;
    text-align: center;
    font-size: 2vmax;
    font-family: "Times New Roman", Times, serif;
    padding: 12px 15px;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 0;
    font-weight: 500;
    overflow: hidden;
    min-width: max-content;
    border-radius: 0 0 0px 20px;
  }

.service_mobile_card > div:nth-child(1) > img {
  aspect-ratio: 4/3;
  width: 100%;
}

.service_mobile_card > div:nth-child(2) {
  padding: 0 10px;
}

.service_mobile_card > div:nth-child(2) > p {
  color: var(--color-white);
  line-height: 1.2em;
  letter-spacing: 1px;
}
.serviceButton{
  text-decoration:none;
  font: 500 30px sans-serif;
  color: black;
}

.serviceButtonIcon {
  rotate: 180deg;
  
}

.serviceButtonIconComponent {
  display: flex;
  flex-direction: row-reverse;
  text-decoration:none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font: 500 30px sans-serif;
  max-width: 10rem;
  margin-top: 10%;
  border-radius: 100px;
  background: linear-gradient(
    to right,
    var(--color-lightblue),
    var(--color-green)
  );
}

@media (max-width: 1600px) {
  /* .card {
    display: none;
  } */
  .card .face .face1 img {
    height: 300px;
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
    mix-blend-mode: color-burn;
    opacity: 100%;
  }
}
@media (max-width: 845px) {
  .card {
    display: none;
  }
}