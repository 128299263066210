body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-web:#AB6034;
  --color-darkGray:#1D1B1A ;
  --color-java: #1cc1b4;
  --color-yellow: #eac67a;
  --color-charm: #d6809c;
  --color-green: #8ada5f;
  --color-lightblue: #1cc1c2;
  --color-light-green: #e5ffed;
  --color-badge: #0a3b50;
  --color-dark-green: #0a3b50;
  --color-red: #f53f33;
  --color-gray: #909090;
  --color-black-green:linear-gradient(135deg, orange 60%, cyan);;
  --color-background-linear-gradient-color: linear-gradient(
    to right,
    var(--color-lightblue),
    var(--color-green)
  );

  /* --font-family */
  --e-global-typography-accent-font-family: "Space Grotesk";

  /* --animation */
  --global-zoom-in-out: zoom-in-zoom-out;
  --global-line-swipe: slide-in-anim;
  --global-slide-out: slide-out-anim;
  --global-from-right: slide-from-right;

  /* --slide one background image */
  /* --slide-one-image: url("/public//images/IMG_8439.jpg");
  --slide-two-image: url("/public//images/coulser-05.jpg"); */
}

.container-lg {
  width: 90% !important;
  max-width: 100% !important;
  padding: 10px;
}

.container-md {
  width: 80% !important;
  max-width: 100% !important;
  padding: 10px;
}

.container-sm {
  width: 70% !important;
  max-width: 100% !important;
  padding: 10px;
}

.container-xs {
  width: 60% !important;
  max-width: 100% !important;
  padding: 10px;
}
.backGroundTheamColor{
  background-image: linear-gradient(
    to right,
    var(--color-darkGray),
    var(--color-web)
  );

}
.backGroundPrimaryColor {
  background-image: linear-gradient(
    to right,
    var(--color-lightblue),
    var(--color-green)
  );
}

.globalLinearGradientTextStyle {
  background-image: linear-gradient(
    to right,
    var(--color-lightblue),
    var(--color-green)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.7, 0.7);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes slide-in-anim {
  0% {
    opacity: 0;
    transform: translateX(-45%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slide-from-right {
  0% {
    opacity: 0;
    transform: translateX(45%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slide-out-anim {
  0% {
    opacity: 0;
    transform: translateY(45%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.divider {

  background-color: transparent;
  background-image: linear-gradient(
    to right,
    var(--color-darkGray) 0,
    var(--color-web) 100%
  );
  -webkit-mask-image: url("/public/assets/images/divider-01-min.png");
  -webkit-mask-size: contain;
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

:is(
    .footer_gallery_image_1,
    .footer_gallery_image_2,
    .footer_gallery_image_3,
    .footer_gallery_image_4,
    .footer_gallery_image_5,
    .footer_gallery_image_6,
    .footer_gallery_image_7
  ) {
  height: 70px;
  overflow: hidden;
  cursor: pointer;
}

:is(
    .footer_gallery_image_1,
    .footer_gallery_image_2,
    .footer_gallery_image_3,
    .footer_gallery_image_4,
    .footer_gallery_image_5,
    .footer_gallery_image_6,
    .footer_gallery_image_7
  )
  > img {
  transition: all 0.5s ease-in-out;
}

:is(
    .footer_gallery_image_1,
    .footer_gallery_image_2,
    .footer_gallery_image_3,
    .footer_gallery_image_4,
    .footer_gallery_image_5,
    .footer_gallery_image_6,
    .footer_gallery_image_7
  ):hover
  > img {
  scale: 1.1;
}

.footer_gallery_image_1 {
  width: 50px;
}

.footer_gallery_image_5 {
  width: 100px;
}

.footer_gallery_image_2,
.footer_gallery_image_4 {
  width: 70px;
}

.footer_gallery_image_6 {
  width: 90px;
}

.footer_gallery_image_7 {
  width: 100px;
}

.footer_gallery_image_3 {
  width: 100px;
}

.footer_gallery_image_1 > img {
  object-fit: cover;
}

.react-datepicker-wrapper{
  border-style: none
}
.react-datepicker__input-container {
  border-style: none

}