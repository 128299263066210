* .chips {
    
    box-shadow: 0px 0px 0px #000;
    transition:all 300ms ease-in-out
}
.chips:hover{
    box-shadow: 0px 8px 34px;
    transform: translateY(4px);
    background-color: #fff;
}
* .herochips {
    
    box-shadow: 0px 0px 0px #000;
    transition:all 300ms ease-in-out
}
.herochips:hover{
    box-shadow: 0px 8px 25px;
    transform: translateY(4px);
    background-color: #fff;
}